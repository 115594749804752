import React from 'react'
import * as HomeLogosStyles from '../styles/HomeLogos.css'

const HomeLogos: React.VFC = () => {
  const logos = (
    <ul className={HomeLogosStyles.Listing}>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/zozotown-logo.webp"
          width="103"
          height="52"
          alt="ZOZOTOWNロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/baycrews-logo.webp"
          width="103"
          height="52"
          alt="BAYCREW'S STOREロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img src="/assets/images/home/lenovo-logo.webp" width="103" height="52" alt="Lenovoロゴ" className={HomeLogosStyles.Listing_Img} />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/saishunkan-logo.webp"
          width="103"
          height="52"
          alt="再春館製薬ロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/mitsuidirectsonpo-logo.webp"
          width="103"
          height="52"
          alt="三井ダイレクト損保ロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/lifenet-seimei-logo.webp"
          width="103"
          height="52"
          alt="ライフネット生命ロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/zozotown-logo.webp"
          width="103"
          height="52"
          alt="ZOZOTOWNロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/baycrews-logo.webp"
          width="103"
          height="52"
          alt="BAYCREW'S STOREロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img src="/assets/images/home/lenovo-logo.webp" width="103" height="52" alt="Lenovoロゴ" className={HomeLogosStyles.Listing_Img} />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img src="/assets/images/home/lifull-logo.webp" width="103" height="52" alt="LIFULLロゴ" className={HomeLogosStyles.Listing_Img} />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/sm-rakuten-logo.webp"
          width="103"
          height="52"
          alt="楽天西友ネットスーパーロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/happy-bears-logo.webp"
          width="103"
          height="52"
          alt="ベアーズロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/bellface-logo.webp"
          width="103"
          height="52"
          alt="ベルフェイスロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/globis-logo.webp"
          width="103"
          height="52"
          alt="グロービスロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img src="/assets/images/home/lifull-logo.webp" width="103" height="52" alt="LIFULLロゴ" className={HomeLogosStyles.Listing_Img} />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/sm-rakuten-logo.webp"
          width="103"
          height="52"
          alt="楽天西友ネットスーパーロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img src="/assets/images/home/mineo-logo.webp" width="103" height="52" alt="mineoロゴ" className={HomeLogosStyles.Listing_Img} />
      </li>
      <li className={HomeLogosStyles.Listing_Item}>
        <img
          src="/assets/images/home/bellface-logo.webp"
          width="103"
          height="52"
          alt="ベルフェイスロゴ"
          className={HomeLogosStyles.Listing_Img}
        />
      </li>
    </ul>
  )

  return (
    <div className={HomeLogosStyles.Container}>
      {logos}
      {logos}
    </div>
  )
}

export default HomeLogos
