import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faHandHoldingHeart } from '@fortawesome/pro-regular-svg-icons'
import * as SiteWideStyles from '../styles/SiteWide.css'
import * as HomeSupportStyles from '../styles/HomeSupport.css'
import { FormattedMessage } from 'react-intl'
import { isLanguageJa } from '../utils/is-language-ja'
import { formattedMessageValues } from '../utils/FormattedMessageValues'

const seminars = [
  {
    headline: 'LPOの効果的な進め方',
    description: '所要時間 48min',
    link: 'https://karte.io/seminar/on-demand/20221024_karte-blocks_ondemand/',
    imageSrc: '/assets/images/home/seminar_1.webp',
  },
  {
    headline: 'KARTE Blocksにおけるオンボーディング改善のリアル',
    description: '所要時間 40min',
    link: 'https://karte.io/seminar/on-demand/20220526-saas-blocks-ondemand/',
    imageSrc: '/assets/images/home/seminar_2.webp',
  },
]

const materials = [
  {
    headline: '直感的なサイト編集・CVR向上｜KARTE Blocks',
    description: '#サイト管理・改善',
    link: 'https://blocks.karte.io/ebook/',
    imageSrc: '/assets/images/home/material_1.webp',
  },
  {
    headline: '成功事例に学ぶ、LPOのキホン',
    description: '#サイト管理・改善',
    link: 'https://blocks.karte.io/ebook/lpo/',
    imageSrc: '/assets/images/home/material_2.webp',
  },
]

const HomeSupport: React.VFC = () => {
  const isJa = isLanguageJa()
  const supports = [
    {
      headline: <FormattedMessage id="pages.top.support.item1.title" defaultMessage="チャット" values={{ ...formattedMessageValues }} />,
      description: (
        <FormattedMessage
          id="pages.top.support.item1.lead"
          defaultMessage="お客様の管理画面を見ながら<strong>オンライン</strong>で日々の運用のサポートをさせていただきます。（平日10:30 - 17:30）"
          values={{ ...formattedMessageValues }}
        />
      ),
      image: <StaticImage alt="チャット" src="../images/home/support_1.webp" width={150} height={130} layout="constrained" />,
    },
    {
      headline: (
        <FormattedMessage
          id="pages.top.support.item2.title"
          defaultMessage="個別相談（オンライン）"
          values={{ ...formattedMessageValues }}
        />
      ),
      description: (
        <FormattedMessage
          id="pages.top.support.item2.lead"
          defaultMessage="プロダクトの仕組みから実践的な使い方まで、<strong>KARTE Blocks専門チーム</strong>がご支援いたします。"
          values={{ ...formattedMessageValues }}
        />
      ),
      image: <StaticImage alt="個別相談（オンライン）" src="../images/home/support_2.webp" width={150} height={130} layout="constrained" />,
    },
    {
      headline: (
        <FormattedMessage id="pages.top.support.item3.title" defaultMessage="サポートサイト" values={{ ...formattedMessageValues }} />
      ),
      description: (
        <FormattedMessage
          id="pages.top.support.item3.lead"
          defaultMessage="KARTE Blocksのセットアップ、日々のご利用に関して情報をおまとめしています。"
          values={{ ...formattedMessageValues }}
        />
      ),
      image: <StaticImage alt="サポートサイト" src="../images/home/support_3.webp" width={150} height={130} layout="constrained" />,
    },
    {
      headline: (
        <FormattedMessage id="pages.top.support.item4.title" defaultMessage="テンプレートストア" values={{ ...formattedMessageValues }} />
      ),
      description: (
        <FormattedMessage
          id="pages.top.support.item4.lead"
          defaultMessage="プロがデザインしたブロックのテンプレートやイベントなど、簡単にご利用いただけます。"
          values={{ ...formattedMessageValues }}
        />
      ),
      image: <StaticImage alt="テンプレートストア" src="../images/home/support_4.webp" width={150} height={130} layout="constrained" />,
    },
  ]

  return (
    <section className={HomeSupportStyles.Container}>
      <div className={HomeSupportStyles.Inner}>
        <p className={HomeSupportStyles.SubTitle}>
          <FormattedMessage id="pages.top.support.eyebrow" defaultMessage="For comfortable use" values={{ ...formattedMessageValues }} />
        </p>
        <h2 className={HomeSupportStyles.Headline}>
          <FormattedMessage
            id="pages.top.support.title"
            defaultMessage="万全なサポート体制で{brMobile}ご活用を支援"
            values={{ ...formattedMessageValues }}
          />
        </h2>
        <ul className={HomeSupportStyles.Support_Listing}>
          {supports.map((support, index) => (
            <li key={`support_${index}`} className={HomeSupportStyles.Support_ListingItem}>
              <div className={HomeSupportStyles.Support_ListingItem_Image}>{support.image}</div>
              <div className={HomeSupportStyles.Support_Content}>
                <p className={HomeSupportStyles.Support_Headline}>{support.headline}</p>
                <p className={HomeSupportStyles.Support_Desctiption}>{support.description}</p>
              </div>
            </li>
          ))}
        </ul>

        <div className={HomeSupportStyles.Guide}>
          <FontAwesomeIcon icon={faHandHoldingHeart} className={HomeSupportStyles.Guide_Icon} width={36} height={32} />
          <div className={HomeSupportStyles.Guide_Text}>
            <FormattedMessage
              id="pages.top.support.guide"
              defaultMessage="タグ実装やコード記述支援、数値分析や改善提案コンサルティングなどもご希望の場合、別途有償サポートをご案内もしくは連携パートナー企業をご紹介いたします。"
              values={{ ...formattedMessageValues }}
            />
          </div>
        </div>
        {isJa && (
          <div className={cx(HomeSupportStyles.Info)}>
            <div className={HomeSupportStyles.Info_Block}>
              <div className={HomeSupportStyles.Info_Headlines}>
                <h3 className={HomeSupportStyles.Info_Headline}>セミナー・イベント</h3>
                <a
                  href="https://karte.io/seminar/"
                  target={'_blank'}
                  className={cx(HomeSupportStyles.Info_Button, SiteWideStyles.DisplayDesktopOnly)}>
                  一覧を見る
                  <FontAwesomeIcon icon={faArrowRight} className={HomeSupportStyles.Info_Icon} width={13} height={14} />
                </a>
              </div>
              <ul className={HomeSupportStyles.Info_Listing}>
                {seminars.map((seminar) => (
                  <li key={seminar.headline}>
                    <a href={seminar.link} target="_blank" className={HomeSupportStyles.Info_Card}>
                      <img
                        src={seminar.imageSrc}
                        alt={seminar.headline}
                        className={HomeSupportStyles.Info_CardImage}
                        height={116}
                        width={216}
                      />
                      <div className={HomeSupportStyles.Info_CardContent}>
                        <p className={HomeSupportStyles.Info_CardHeadline}>{seminar.headline}</p>
                        <p className={HomeSupportStyles.Info_CardDescription}>{seminar.description}</p>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
              <a
                href="https://karte.io/seminar/"
                target={'_blank'}
                className={cx(HomeSupportStyles.Info_Button, HomeSupportStyles.Info_MobileButton, SiteWideStyles.DisplayMobileOnly)}>
                一覧を見る
                <FontAwesomeIcon icon={faArrowRight} className={HomeSupportStyles.Info_Icon} width={13} height={14} />
              </a>
            </div>
            <div className={HomeSupportStyles.Info_Block}>
              <div className={HomeSupportStyles.Info_Headlines}>
                <h3 className={HomeSupportStyles.Info_Headline}>お役立ち資料</h3>
                <a
                  href="https://karte.io/resource/ebook/"
                  target={'_blank'}
                  className={cx(HomeSupportStyles.Info_Button, SiteWideStyles.DisplayDesktopOnly)}>
                  一覧を見る
                  <FontAwesomeIcon icon={faArrowRight} className={HomeSupportStyles.Info_Icon} width={13} height={14} />
                </a>
              </div>
              <ul className={HomeSupportStyles.Info_Listing}>
                {materials.map((material) => (
                  <li key={material.headline}>
                    <a href={material.link} target="_blank" className={HomeSupportStyles.Info_Card}>
                      <img
                        src={material.imageSrc}
                        alt={material.headline}
                        className={HomeSupportStyles.Info_CardImage}
                        height={116}
                        width={216}
                      />
                      <div className={HomeSupportStyles.Info_CardContent}>
                        <p className={HomeSupportStyles.Info_CardHeadline}>{material.headline}</p>
                        <p className={HomeSupportStyles.Info_CardDescription}>{material.description}</p>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
              <a
                href="https://karte.io/resource/ebook/"
                target={'_blank'}
                className={cx(HomeSupportStyles.Info_Button, HomeSupportStyles.Info_MobileButton, SiteWideStyles.DisplayMobileOnly)}>
                一覧を見る
                <FontAwesomeIcon icon={faArrowRight} className={HomeSupportStyles.Info_Icon} width={13} height={14} />
              </a>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default HomeSupport
