import React, { useEffect, useRef, useCallback } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as HomeControlPanelStyles from '../styles/HomeControlPanel.css'

const HomeControlPanel: React.VFC = () => {
  const listingElement = useRef<HTMLUListElement>(null)

  const scroll = useCallback(() => {
    const mobile = window.matchMedia('(max-width: 767px)').matches

    const parent = listingElement.current?.parentElement?.clientWidth
    const width = listingElement.current?.firstElementChild?.clientWidth

    if (parent && width) {
      const distance = mobile ? width - parent * 0.064 * 0.75 : (2260 - parent) / 2

      listingElement.current?.scrollTo(distance, 0)
    }
  }, [])

  const handle = useCallback((e) => e.matches && scroll(), [scroll])

  useEffect(() => {
    const over = window.matchMedia('(max-width: 2139px)')

    over.matches && scroll()
    over.addEventListener('change', handle)

    return () => over.removeEventListener('change', handle)
  }, [handle])

  return (
    <div className={HomeControlPanelStyles.Container}>
      <ul ref={listingElement} className={HomeControlPanelStyles.Listing}>
        <li className={HomeControlPanelStyles.Listing_Item}>
          <StaticImage className={HomeControlPanelStyles.Panel_Image} src="../images/home/control_panel_1.webp" alt="効果計測結果画面" />
        </li>
        <li className={HomeControlPanelStyles.Listing_Item}>
          <StaticImage className={HomeControlPanelStyles.Panel_Image} src="../images/home/control_panel_2.webp" alt="ブロック編集画面" />
        </li>
        <li className={HomeControlPanelStyles.Listing_Item}>
          <StaticImage
            className={HomeControlPanelStyles.Panel_Image}
            src="../images/home/control_panel_3.webp"
            alt="ブロックパターン登録"
          />
        </li>
      </ul>
    </div>
  )
}

export default HomeControlPanel
