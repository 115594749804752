import React from 'react'
import * as SiteWideStyles from '../styles/SiteWide.css'
import * as HomeConceptStyles from '../styles/HomeConcept.css'
import { FormattedMessage } from 'react-intl'
import { formattedMessageValues } from '../utils/FormattedMessageValues'

const HomeConcept: React.VFC = () => {
  return (
    <section className={HomeConceptStyles.Container}>
      <span className={HomeConceptStyles.SmallHeadline}>
        <FormattedMessage
          id="pages.top.concept.eyebrow"
          defaultMessage="What’s KARTE Blocks? Why KARTE Blocks?"
          values={{ ...formattedMessageValues }}
        />
      </span>
      <h2 className={HomeConceptStyles.Headline}>
        <FormattedMessage
          id="pages.top.concept.title"
          defaultMessage="できない、わからない{brMobile}サイト運営を、{br} 誰でも自由に直感的に改善。"
          values={{ ...formattedMessageValues }}
        />
      </h2>
      <div className={HomeConceptStyles.Inner}>
        <dl className={HomeConceptStyles.Listing}>
          <div className={HomeConceptStyles.Listing_Item}>
            <dt className={HomeConceptStyles.Listing_Headline}>
              <span className={HomeConceptStyles.Listing_Number}>
                <span className={HomeConceptStyles.Listing_Feature}>FEATURE</span>1
              </span>
              <FormattedMessage
                id="pages.top.concept.feature1.title"
                defaultMessage="直感的な編集"
                values={{ ...formattedMessageValues }}
              />
            </dt>
            <dd className={HomeConceptStyles.Listing_Summary}>
              <FormattedMessage
                id="pages.top.concept.feature1.lead"
                defaultMessage="あなたのサイトをどこでもブロック化<spanMobile>。</spanMobile>{brDesktop}ノーコードですぐに改修"
                values={{ ...formattedMessageValues }}
              />
              <div className={HomeConceptStyles.Listing_Illustration}>
                <img
                  src="/assets/images/home/concept-illustration_1.webp"
                  width="300"
                  height="245"
                  alt="あなたのサイトをどこでもブロック化。ノーコードですぐに改修"
                  className={HomeConceptStyles.Listing_Image}
                />
              </div>
            </dd>
          </div>
          <div className={HomeConceptStyles.Listing_Item}>
            <dt className={HomeConceptStyles.Listing_Headline}>
              <span className={HomeConceptStyles.Listing_Number}>
                <span className={HomeConceptStyles.Listing_Feature}>FEATURE</span>2
              </span>
              <FormattedMessage
                id="pages.top.concept.feature2.title"
                defaultMessage="思い通りに配信"
                values={{ ...formattedMessageValues }}
              />
            </dt>
            <dd className={HomeConceptStyles.Listing_Summary}>
              <FormattedMessage
                id="pages.top.concept.feature2.lead"
                defaultMessage="ABテスト / パーソナライズも{br}自由自在に"
                values={{ ...formattedMessageValues }}
              />
              <div className={HomeConceptStyles.Listing_Illustration}>
                <img
                  src="/assets/images/home/concept-illustration_2.webp"
                  width="300"
                  height="230"
                  alt="ABテスト / パーソナライズも自由自在に"
                  className={HomeConceptStyles.Listing_Image}
                />
              </div>
            </dd>
          </div>
          <div className={HomeConceptStyles.Listing_Item}>
            <dt className={HomeConceptStyles.Listing_Headline}>
              <span className={HomeConceptStyles.Listing_Number}>
                <span className={HomeConceptStyles.Listing_Feature}>FEATURE</span>3
              </span>
              <FormattedMessage
                id="pages.top.concept.feature3.title"
                defaultMessage="正しくデータ評価"
                values={{ ...formattedMessageValues }}
              />
            </dt>
            <dd className={HomeConceptStyles.Listing_Summary}>
              <FormattedMessage
                id="pages.top.concept.feature3.lead"
                defaultMessage="効果を自動集計・ 可視化。{br}データから新たな発見"
                values={{ ...formattedMessageValues }}
              />
              <div className={HomeConceptStyles.Listing_Illustration}>
                <img
                  src="/assets/images/home/concept-illustration_3.webp"
                  width="300"
                  height="230"
                  alt="効果を自動集計・ 可視化。データから新たな発見"
                  className={HomeConceptStyles.Listing_Image}
                />
              </div>
            </dd>
          </div>
          <div className={HomeConceptStyles.Listing_Item}>
            <dt className={HomeConceptStyles.Listing_Headline}>
              <span className={HomeConceptStyles.Listing_Number}>
                <span className={HomeConceptStyles.Listing_Feature}>FEATURE</span>4
              </span>
              <FormattedMessage
                id="pages.top.concept.feature4.title"
                defaultMessage="ユーザーを見る"
                values={{ ...formattedMessageValues }}
              />
            </dt>
            <dd className={HomeConceptStyles.Listing_Summary}>
              <FormattedMessage
                id="pages.top.concept.feature4.lead"
                defaultMessage="ユーザーの反応を深掘り{br}次につながる発見と 仮説を生み出す"
                values={{ ...formattedMessageValues }}
              />
              <div className={HomeConceptStyles.Listing_Illustration}>
                <img
                  src="/assets/images/home/concept-illustration_4.webp"
                  width="300"
                  height="255"
                  alt="ユーザーの反応を深掘り次につながる発見と仮説を生み出す"
                  className={HomeConceptStyles.Listing_Image}
                />
              </div>
            </dd>
          </div>
        </dl>
        <div className={HomeConceptStyles.Conclusion}>
          <svg className={HomeConceptStyles.Arrow} width="115" height="28" viewBox="0 0 115 28">
            <path d="M61.482 26.271a10 10 0 01-7.964 0L1.833 3.835C-.157 2.97.46 0 2.63 0h109.74c2.17 0 2.787 2.97.797 3.835L61.482 26.27z" />
          </svg>
          <img
            src="/assets/images/home/concept-background-bubble.webp"
            width="370"
            height="133"
            alt=""
            className={HomeConceptStyles.Bubble_Image}
          />
          <div className={HomeConceptStyles.Bubble}>
            <img src="/assets/images/home/concept-logo.webp" width="37" height="38" alt="KARTE Blocks" className={HomeConceptStyles.Logo} />
            <div className={HomeConceptStyles.Lead}>
              <FormattedMessage
                id="pages.top.concept.lead"
                defaultMessage="KARTE Blocksでかなえる{br}「リーン・サイト運営」"
                values={{ ...formattedMessageValues }}
              />
            </div>
          </div>
          <svg className={HomeConceptStyles.Circles} width="475" height="155" viewBox="0 0 475 155">
            <ellipse cx="133" cy="132.5" rx="23" ry="22.5" />
            <ellipse cx="409" cy="62" rx="46" ry="47" />
            <circle cx="56.5" cy="56.5" r="56.5" />
            <circle cx="391" cy="122" r="30" />
          </svg>
        </div>
        <img
          src="/assets/images/home/concept-background-illustration.svg"
          width="588"
          height="815"
          alt=""
          className={HomeConceptStyles.Background}
        />
      </div>
    </section>
  )
}

export default HomeConcept
