import React from 'react'
import { Helmet } from 'react-helmet'
import { IntlProvider } from 'react-intl'
import { PageContextPropsType } from '../../i18n/i18n'
import SEO from '../components/SEO'
import LocalizePage from '../components/foundation/LocalizePage'
import HomePageTemplate from '../templates/home'
import * as HomePageStyles from '../styles/HomePage.css'

export default function HomePage({ pageContext }: PageContextPropsType) {
  const language = pageContext.language // "en" or "ja"
  const messages = pageContext.messages // en-US.json content or ja.json content
  const metadata = {
    page_title:
      typeof messages['pages.top.meta.title'] === 'string'
        ? messages['pages.top.meta.title']
        : 'リーン・サイト運営プラットフォーム KARTE Blocks | あなたのサイト改善 あっという間に。継続的に。',
    description:
      typeof messages['pages.top.meta.description'] === 'string'
        ? messages['pages.top.meta.description']
        : 'KARTE Blocksは、ウェブサイトにタグを導入するだけで、あらゆるコンテンツをノーコードで瞬時に編集・ABテスト・パーソナライズし、定量的なアクセス分析とユーザーの行動ログから直感的な発見をワンストップで提供します。チームによるサイト運営の生産性と改善速度を高めながらCX向上を実現する、リーン・サイト運営プラットフォームです。',
    site_type: 'website',
    page_path: language === 'ja' ? '/' : `/${language}/`,
  }

  return (
    <IntlProvider locale={language} messages={messages}>
      <SEO title={metadata.page_title} description={metadata.description} path="/">
        <link
          rel="preload"
          as="image"
          href="/assets/images/home/hero-bg_mobile.webp"
          imageSrcSet="/assets/images/home/hero-bg_tablet.webp 480w, /assets/images/home/hero-bg_desktop.webp 768w, /assets/images/home/hero-bg_desktop_2.webp 1281w"
          imageSizes="100vw"
        />
      </SEO>
      <LocalizePage pagePath="/" />
      <Helmet>
        <html className={HomePageStyles.Html} lang={language} />
      </Helmet>
      <HomePageTemplate />
    </IntlProvider>
  )
}
