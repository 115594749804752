import React, { useEffect, useRef, useCallback } from 'react'
import * as HomeCustomerCommentStyles from '../styles/HomeCustomerComment.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FormattedMessage } from 'react-intl'
import { formattedMessageValues } from '../utils/FormattedMessageValues'
import { isLanguageJa } from '../utils/is-language-ja'

const HomeCustomerComment: React.VFC = () => {
  const listingElement = useRef<HTMLDivElement>(null)

  const scroll = useCallback(() => {
    const mobile = window.matchMedia('(max-width: 766px)').matches

    const parent = listingElement.current?.parentElement?.clientWidth
    const width = listingElement.current?.firstElementChild?.clientWidth

    if (parent && width && !mobile) listingElement.current?.scrollTo((1234 - parent) / 2, 0)
  }, [])

  const handle = useCallback((e: any) => e.matches && scroll(), [scroll])

  useEffect(() => {
    const over = window.matchMedia('(min-width:767px) and (max-width: 1234px)')

    over.matches && scroll()
    over.addEventListener('change', handle)

    return () => over.removeEventListener('change', handle)
  }, [handle])

  return (
    <section className={HomeCustomerCommentStyles.Container}>
      <span className={HomeCustomerCommentStyles.SmallHeadline}>
        <FormattedMessage
          id="pages.top.customerComment.eyebrow"
          defaultMessage="With KARTE Blocks"
          values={{ ...formattedMessageValues }}
        />
      </span>
      <h2 className={HomeCustomerCommentStyles.Headline}>
        <FormattedMessage
          id="pages.top.customerComment.title"
          defaultMessage="お客様の声 / 導入後の変化"
          values={{ ...formattedMessageValues }}
        />
      </h2>
      <p className={HomeCustomerCommentStyles.Lead}>
        <FormattedMessage
          id="pages.top.customerComment.lead"
          defaultMessage="工数や改修コストを大幅削減、スピーディな検証と改善で成果向上。{br}チームがもっと前のめりになり、新メンバーの育成 / 権限移譲にも"
          values={{ ...formattedMessageValues }}
        />
      </p>
      {isLanguageJa() && (
        <div ref={listingElement} className={HomeCustomerCommentStyles.Article}>
          <a href="https://blocks.karte.io/blog/userstory_ms&ad/" className={HomeCustomerCommentStyles.Article_Item}>
            <img
              src="/assets/images/home/customer-thumbnail_1.jpg"
              alt="「こんなに簡単にできるのか」三井住友海上が取り組む、顧客の反応起点の高速サイト改善"
              width="360"
              height="240"
              className={HomeCustomerCommentStyles.Article_Thumbnail}
              loading="lazy"
            />
            <div className={HomeCustomerCommentStyles.Article_Body}>
              <ul className={HomeCustomerCommentStyles.Article_Tags}>
                <li className={HomeCustomerCommentStyles.Article_Tag}>#ABテスト</li>
                <li className={HomeCustomerCommentStyles.Article_Tag}>#金融</li>
              </ul>
              <div className={HomeCustomerCommentStyles.Article_Content}>
                <p className={HomeCustomerCommentStyles.Article_Lead}>
                  とにかく試してみようとスピード感を持って実施してPDCAを回しているので、それが奏功していると思います。
                  <b className={HomeCustomerCommentStyles.Article_Bold}>こんなにうまく、簡単に改善していけるのだと、正直驚いている</b>
                  くらいです。...
                </p>
                <div className={HomeCustomerCommentStyles.Article_Link}>
                  Read the interview
                  <FontAwesomeIcon icon={faArrowRight} className={HomeCustomerCommentStyles.Article_Icon} width={10} height={12} />
                </div>
              </div>
              <div className={HomeCustomerCommentStyles.Article_Company}>
                <img
                  src="/assets/images/home/customer-mitsuidirectsonpo-logo.webp"
                  alt="三井住友海上火災保険株式会社"
                  width="72"
                  height="45"
                  className={HomeCustomerCommentStyles.Article_Logo}
                />
                <div className={HomeCustomerCommentStyles.Article_Staff}>西尾様、目次様、稲満様</div>
                <div className={HomeCustomerCommentStyles.Article_Name}>三井住友海上火災保険株式会社</div>
              </div>
            </div>
          </a>
          <a href="https://blocks.karte.io/blog/userstory_kiramex/" className={HomeCustomerCommentStyles.Article_Item}>
            <img
              src="/assets/images/home/customer-thumbnail_2.jpg"
              alt="市場と顧客のニーズの変化を捉える。「前に進む」PDCAで成果を生み出すTechAcademyのサイト運営"
              width="360"
              height="223"
              className={HomeCustomerCommentStyles.Article_Thumbnail}
              loading="lazy"
            />
            <div className={HomeCustomerCommentStyles.Article_Body}>
              <ul className={HomeCustomerCommentStyles.Article_Tags}>
                <li className={HomeCustomerCommentStyles.Article_Tag}>#人材</li>
                <li className={HomeCustomerCommentStyles.Article_Tag}>#パーソナライズ</li>
              </ul>
              <div className={HomeCustomerCommentStyles.Article_Content}>
                <p className={HomeCustomerCommentStyles.Article_Lead}>
                  <b className={HomeCustomerCommentStyles.Article_Bold}>KARTE Blocksを活用した施策数は3ヶ月で100を超え</b>
                  ています。「新しい表現や訴求をどんどん試していきたい」という私たちのニーズに応えてくれていると思います。...
                </p>
                <div className={HomeCustomerCommentStyles.Article_Link}>
                  Read the interview
                  <FontAwesomeIcon icon={faArrowRight} className={HomeCustomerCommentStyles.Article_Icon} width={10} height={12} />
                </div>
              </div>
              <div className={HomeCustomerCommentStyles.Article_Company}>
                <img
                  src="/assets/images/home/customer-kiramex-logo.webp"
                  alt="キラメックス株式会社"
                  width="72"
                  height="10"
                  className={HomeCustomerCommentStyles.Article_Logo}
                />

                <div className={HomeCustomerCommentStyles.Article_Staff}>福田様</div>
                <div className={HomeCustomerCommentStyles.Article_Name}>キラメックス株式会社</div>
              </div>
            </div>
          </a>
          <a href="https://blocks.karte.io/blog/userstory_alpenrose/" className={HomeCustomerCommentStyles.Article_Item}>
            <img
              src="/assets/images/home/customer-thumbnail_3.jpg"
              alt="自分たちで自由に変更できないサイトでは、アイデアの実現も難しい。ECサイトをノーコード化し、チームに改善が根付くまで"
              width="360"
              height="223"
              className={HomeCustomerCommentStyles.Article_Thumbnail}
              loading="lazy"
            />
            <div className={HomeCustomerCommentStyles.Article_Body}>
              <ul className={HomeCustomerCommentStyles.Article_Tags}>
                <li className={HomeCustomerCommentStyles.Article_Tag}>#ECサイト</li>
              </ul>
              <div className={HomeCustomerCommentStyles.Article_Content}>
                <p className={HomeCustomerCommentStyles.Article_Lead}>
                  <b className={HomeCustomerCommentStyles.Article_Bold}>作って終わりではなく改善する意識がチームに根付いた</b>
                  <br />
                  施策の結果を次の施策に積み重ねていくことで、メンバーの成果に対する意識が変わってきたのを感じます。...
                </p>
                <div className={HomeCustomerCommentStyles.Article_Link}>
                  Read the interview
                  <FontAwesomeIcon icon={faArrowRight} className={HomeCustomerCommentStyles.Article_Icon} width={10} height={12} />
                </div>
              </div>
              <div className={HomeCustomerCommentStyles.Article_Company}>
                <img
                  src="/assets/images/home/customer-alpenrose-logo.webp"
                  alt="アルペンローゼ株式会社"
                  width="72"
                  height="11"
                  className={HomeCustomerCommentStyles.Article_Logo}
                />
                <div className={HomeCustomerCommentStyles.Article_Staff}>松保様、竹内様</div>
                <div className={HomeCustomerCommentStyles.Article_Name}>アルペンローゼ株式会社</div>
              </div>
            </div>
          </a>
        </div>
      )}
      <div className={HomeCustomerCommentStyles.Case}>
        <div className={HomeCustomerCommentStyles.Case_Head}>
          <h3 className={HomeCustomerCommentStyles.Case_Headline}>
            <FormattedMessage id="pages.top.customerComment.case.title" defaultMessage="導入事例" values={{ ...formattedMessageValues }} />
          </h3>
          <p className={HomeCustomerCommentStyles.Case_Lead}>
            <FormattedMessage
              id="pages.top.customerComment.case.lead"
              defaultMessage="できなかったをできた{br}知らなかったことに気づけた{br}チームの活動サイクルが活発に。"
              values={{ ...formattedMessageValues }}
            />
          </p>
          <a href="https://blocks.karte.io/blog/" className={HomeCustomerCommentStyles.Case_Button}>
            <FormattedMessage
              id="pages.top.customerComment.case.button"
              defaultMessage="もっとみる"
              values={{ ...formattedMessageValues }}
            />

            <FontAwesomeIcon icon={faArrowRight} className={HomeCustomerCommentStyles.Article_Icon} width={12} height={14} />
          </a>
          <svg width="133" height="126" viewBox="0 0 133 126" className={HomeCustomerCommentStyles.Case_Object}>
            <circle cx="41.5" cy="41.5" r="41.5" fill="#faf7fd" />
            <path
              d="M112.162 70.785c2.342.26 3.485 2.994 2.023 4.843l-35.121 44.414c-1.418 1.793-4.24 1.405-5.121-.705L52.785 68.696c-.881-2.11.826-4.39 3.098-4.138l56.279 6.227z"
              stroke="#fbf3ef"
              strokeWidth="2"
            />
          </svg>
        </div>
        <ul className={HomeCustomerCommentStyles.Listing}>
          <li className={HomeCustomerCommentStyles.Listing_Item}>
            <img src="/assets/images/home/zozotown-logo.webp" alt="ZOZOTOWNロゴ" className={HomeCustomerCommentStyles.Listing_Logo} />
          </li>
          <li className={HomeCustomerCommentStyles.Listing_Item}>
            <img
              src="/assets/images/home/baycrews-logo.webp"
              alt="BAYCREW'S STOREロゴ"
              className={HomeCustomerCommentStyles.Listing_Logo}
            />
          </li>
          <li className={HomeCustomerCommentStyles.Listing_Item}>
            <img src="/assets/images/home/lenovo-logo.webp" alt="Lenovoロゴ" className={HomeCustomerCommentStyles.Listing_Logo} />
          </li>
          <li className={HomeCustomerCommentStyles.Listing_Item}>
            <img src="/assets/images/home/saishunkan-logo.webp" alt="再春館製薬ロゴ" className={HomeCustomerCommentStyles.Listing_Logo} />
          </li>
          <li className={HomeCustomerCommentStyles.Listing_Item}>
            <img
              src="/assets/images/home/lifenet-seimei-logo.webp"
              alt="ライフネット生命ロゴ"
              className={HomeCustomerCommentStyles.Listing_Logo}
            />
          </li>
          <li className={HomeCustomerCommentStyles.Listing_Item}>
            <img src="/assets/images/home/lifull-logo.webp" alt="LIFULLロゴ" className={HomeCustomerCommentStyles.Listing_Logo} />
          </li>
          <li className={HomeCustomerCommentStyles.Listing_Item}>
            <img
              src="/assets/images/home/sm-rakuten-logo.webp"
              alt="楽天西友ネットスーパーロゴ"
              className={HomeCustomerCommentStyles.Listing_Logo}
            />
          </li>
          <li className={HomeCustomerCommentStyles.Listing_Item}>
            <img src="/assets/images/home/happy-bears-logo.webp" alt="ベアーズロゴ" className={HomeCustomerCommentStyles.Listing_Logo} />
          </li>
          <li className={HomeCustomerCommentStyles.Listing_Item}>
            <img src="/assets/images/home/bellface-logo.webp" alt="ベルフェイスロゴ" className={HomeCustomerCommentStyles.Listing_Logo} />
          </li>
          <li className={HomeCustomerCommentStyles.Listing_Item}>
            <img src="/assets/images/home/globis-logo.webp" alt="グロービスロゴ" className={HomeCustomerCommentStyles.Listing_Logo} />
          </li>
          <li className={HomeCustomerCommentStyles.Listing_Item}>
            <img
              src="/assets/images/home/mitsuidirectsonpo-logo.webp"
              alt="三井ダイレクト損保ロゴ"
              className={HomeCustomerCommentStyles.Listing_Logo}
            />
          </li>
          <li className={HomeCustomerCommentStyles.Listing_Item}>
            <img src="/assets/images/home/mineo-logo.webp" alt="mineoロゴ" className={HomeCustomerCommentStyles.Listing_Logo} />
          </li>
        </ul>
      </div>
    </section>
  )
}

export default HomeCustomerComment
