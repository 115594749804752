import React from 'react'
import { Helmet } from 'react-helmet'

type LocalizePageProps = {
  pagePath: string
}

const LocalizePage = ({ pagePath }: LocalizePageProps) => {
  return (
    <Helmet>
      <link rel="alternate" hrefLang="en" href={`https://blocks.karte.io/en${pagePath}`} />
      <link rel="alternate" hrefLang="ja" href={`https://blocks.karte.io${pagePath}`} />
      <link rel="alternate" hrefLang="x-default" href={`https://blocks.karte.io${pagePath}`} />
    </Helmet>
  )
}

export default LocalizePage
