import React from 'react'
import cx from 'classnames'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as SiteWideStyles from '../styles/SiteWide.css'
import * as HomeHeroStyles from '../styles/HomeHero.css'
import { FormattedMessage } from 'react-intl'
import { formattedMessageValues } from '../utils/FormattedMessageValues'

const HomeHero: React.VFC = () => {
  return (
    <section className={HomeHeroStyles.Container}>
      <div className={HomeHeroStyles.Inner}>
        <div className={HomeHeroStyles.Content}>
          <p className={HomeHeroStyles.SubHeadline}>
            <FormattedMessage
              id="pages.top.hero.eyebrow"
              defaultMessage="リーン・サイト運営 プラットフォーム | {brMobile}KARTE Blocks"
              values={{ ...formattedMessageValues }}
            />
          </p>
          <h1 className={HomeHeroStyles.Headline}>
            <FormattedMessage
              id="pages.top.hero.title"
              defaultMessage="あなたのサイト改善{br}あっという間に。{brMobile}継続的に。"
              values={{ ...formattedMessageValues }}
            />
          </h1>
          <p className={HomeHeroStyles.Lead}>
            <FormattedMessage
              id="pages.top.hero.lead"
              defaultMessage="チャレンジの数だけ、チームが強くなる。{br}<spanDesktop>サイトを</spanDesktop>どこでも10秒で改善 / ABテスト / パーソナライズ。"
              values={{ ...formattedMessageValues }}
            />
          </p>
          <div className={HomeHeroStyles.ButtonList}>
            <div className={HomeHeroStyles.ButtonOuter}>
              <p className={cx(HomeHeroStyles.ButtonPrText, SiteWideStyles.DisplayDesktopOnly)}>
                <FormattedMessage id="pages.top.hero.button1.eyebrow" defaultMessage="3分でわかる" values={{ ...formattedMessageValues }} />
              </p>
              <a href="https://blocks.karte.io/ebook/" target="_blank" className={cx(HomeHeroStyles.Button, HomeHeroStyles.DownloadButton)}>
                <FormattedMessage id="pages.top.hero.button1" defaultMessage="資料ダウンロード" values={{ ...formattedMessageValues }} />
                <FontAwesomeIcon icon={faArrowRight} className={HomeHeroStyles.Button_Icon} width={18} height={16} />
              </a>
            </div>
            <div className={cx(HomeHeroStyles.ButtonOuter, SiteWideStyles.DisplayDesktopOnly)}>
              <p className={HomeHeroStyles.ButtonPrText}>
                <FormattedMessage
                  id="pages.top.hero.button2.eyebrow"
                  defaultMessage="あなたのサイトで"
                  values={{ ...formattedMessageValues }}
                />
              </p>
              <a
                href="https://karte.io/enterprise/blocks/trial.html"
                target="_blank"
                className={cx(HomeHeroStyles.Button, HomeHeroStyles.DemoButton)}>
                <FormattedMessage id="pages.top.hero.button2" defaultMessage="デモを体感" values={{ ...formattedMessageValues }} />
                <FontAwesomeIcon icon={faArrowRight} className={HomeHeroStyles.Button_Icon} width={18} height={16} />
              </a>
            </div>
          </div>
        </div>
        <picture>
          <source media="(min-width: 1281px)" width="950" height="429" srcSet="/assets/images/home/hero-bg_desktop_2.webp" />
          <source media="(min-width: 768px)" width="1000" height="410" srcSet="/assets/images/home/hero-bg_desktop.webp" />
          <source media="(min-width: 480px)" width="392" height="295" srcSet="/assets/images/home/hero-bg_tablet.webp" />
          <img
            src="/assets/images/home/hero-bg_mobile.webp"
            width="262"
            height="311"
            alt=""
            decoding="async"
            className={HomeHeroStyles.Image}
          />
        </picture>
      </div>
    </section>
  )
}

export default HomeHero
