import { graphql, useStaticQuery } from 'gatsby'

export const useHomeUsecaseImages = () => {
  return useStaticQuery(
    graphql`
      query {
        usecase_1: file(relativePath: { eq: "home/usecase_1.svg" }) {
          publicURL
        }
        usecase_2: file(relativePath: { eq: "home/usecase_2.svg" }) {
          publicURL
        }
        usecase_3: file(relativePath: { eq: "home/usecase_3.svg" }) {
          publicURL
        }
        usecase_4: file(relativePath: { eq: "home/usecase_4.svg" }) {
          publicURL
        }
        usecase_5: file(relativePath: { eq: "home/usecase_5.svg" }) {
          publicURL
        }
        usecase_6: file(relativePath: { eq: "home/usecase_6.svg" }) {
          publicURL
        }
        usecase_7: file(relativePath: { eq: "home/usecase_7.svg" }) {
          publicURL
        }
        usecase_8: file(relativePath: { eq: "home/usecase_8.svg" }) {
          publicURL
        }
      }
    `
  )
}
