import React from 'react'
import classNames from 'classnames/bind'
import LayoutHome from '../components/LayoutHome'
import HomeHero from '../components/HomeHero'
import HomeLogos from '../components/HomeLogos'
import HomeControlPanel from '../components/HomeControlPanel'
import HomeConcept from '../components/HomeConcept'
import HomeCustomerComment from '../components/HomeCustomerComment'
import HomeFeature from '../components/HomeFeature'
import HomeUsecase from '../components/HomeUsecase'
import HomeSupport from '../components/HomeSupport'
import * as HomePageStyles from '../styles/HomePage.css'

const cx = classNames.bind(HomePageStyles)

export default function HomePageTemplate() {
  return (
    <LayoutHome>
      <main className={cx('Container')}>
        <HomeHero />

        <HomeLogos />

        <HomeControlPanel />

        <HomeConcept />

        <HomeCustomerComment />

        <HomeFeature />

        <HomeUsecase />

        <HomeSupport />
      </main>
    </LayoutHome>
  )
}
