import React from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import * as SiteWideStyles from '../styles/SiteWide.css'
import * as HomeUsecaseStyles from '../styles/HomeUsecase.css'
import { useHomeUsecaseImages } from '../utils/use-home-usecase-images'
import { FormattedMessage } from 'react-intl'
import { isLanguageJa } from '../utils/is-language-ja'
import { formattedMessageValues } from '../utils/FormattedMessageValues'

const HomeUsecase: React.VFC = () => {
  const homeUsecaseImages = useHomeUsecaseImages()
  const topUsecases = [
    {
      headline: (
        <FormattedMessage id="pages.top.usecase.top.item1.title" defaultMessage="ABテスト" values={{ ...formattedMessageValues }} />
      ),
      lead: (
        <FormattedMessage
          id="pages.top.usecase.top.item1.lead"
          defaultMessage="複数アイデアを{brDesktop}簡単に比較検証"
          values={{ ...formattedMessageValues }}
        />
      ),
      image: homeUsecaseImages.usecase_1.publicURL,
      link: 'https://blocks.karte.io/blog/tag/ab-test/',
    },
    {
      headline: <FormattedMessage id="pages.top.usecase.top.item2.title" defaultMessage="LPO" values={{ ...formattedMessageValues }} />,
      lead: (
        <FormattedMessage
          id="pages.top.usecase.top.item2.lead"
          defaultMessage="流入ユーザーへ{brDesktop}一目で的確に訴求"
          values={{ ...formattedMessageValues }}
        />
      ),
      image: homeUsecaseImages.usecase_4.publicURL,
      link: 'https://blocks.karte.io/blog/tag/lpo/',
    },
    {
      headline: (
        <FormattedMessage id="pages.top.usecase.top.item3.title" defaultMessage="パーソナライズ" values={{ ...formattedMessageValues }} />
      ),
      lead: (
        <FormattedMessage
          id="pages.top.usecase.top.item3.lead"
          defaultMessage="ユーザー毎に{brDesktop}最適なコンテンツを提供"
          values={{ ...formattedMessageValues }}
        />
      ),
      image: homeUsecaseImages.usecase_3.publicURL,
      link: 'https://blocks.karte.io/blog/tag/personalize/',
    },
    {
      headline: (
        <FormattedMessage id="pages.top.usecase.top.item4.title" defaultMessage="ページ分析" values={{ ...formattedMessageValues }} />
      ),
      lead: (
        <FormattedMessage
          id="pages.top.usecase.top.item4.lead"
          defaultMessage="コンテンツ効果と{brDesktop}ユーザー動向を可視化"
          values={{ ...formattedMessageValues }}
        />
      ),
      image: homeUsecaseImages.usecase_2.publicURL,
      link: 'https://blocks.karte.io/blog/tag/analytics/',
    },
  ]

  const bottomUsecases = [
    {
      headline: (
        <FormattedMessage id="pages.top.usecase.bottom.item1.title" defaultMessage="総合通販 / EC" values={{ ...formattedMessageValues }} />
      ),
      lead: (
        <FormattedMessage
          id="pages.top.usecase.bottom.item1.lead"
          defaultMessage="ユーザーに合わせた{br}導線 / 訴求で売上向上"
          values={{ ...formattedMessageValues }}
        />
      ),
      image: homeUsecaseImages.usecase_5.publicURL,
      link: 'https://blocks.karte.io/blog/tag/matching_sevice/#blocks_ssite',
    },
    {
      headline: (
        <FormattedMessage id="pages.top.usecase.bottom.item2.title" defaultMessage="アパレル" values={{ ...formattedMessageValues }} />
      ),
      lead: (
        <FormattedMessage
          id="pages.top.usecase.bottom.item2.lead"
          defaultMessage="ユーザーに合わせた{br}導線 / 訴求で売上向上"
          values={{ ...formattedMessageValues }}
        />
      ),
      image: homeUsecaseImages.usecase_6.publicURL,
      link: 'https://blocks.karte.io/blog/tag/hr/#blocks_ssite',
    },
    {
      headline: (
        <FormattedMessage
          id="pages.top.usecase.bottom.item3.title"
          defaultMessage="コスメ・美容系"
          values={{ ...formattedMessageValues }}
        />
      ),
      lead: (
        <FormattedMessage
          id="pages.top.usecase.bottom.item3.lead"
          defaultMessage="新規向けLPを改善。{br}広告効果も最大化"
          values={{ ...formattedMessageValues }}
        />
      ),
      image: homeUsecaseImages.usecase_7.publicURL,
      link: 'https://blocks.karte.io/blog/tag/cosme/#blocks_ssite',
    },
    {
      headline: (
        <FormattedMessage id="pages.top.usecase.bottom.item4.title" defaultMessage="BtoB SaaS" values={{ ...formattedMessageValues }} />
      ),
      lead: (
        <FormattedMessage
          id="pages.top.usecase.bottom.item4.lead"
          defaultMessage="検討状況に合わせた訴求で、{br}リードの獲得加速"
          values={{ ...formattedMessageValues }}
        />
      ),
      image: homeUsecaseImages.usecase_8.publicURL,
      link: 'https://blocks.karte.io/blog/tag/saas/#blocks_ssite',
    },
  ]

  return (
    <section className={HomeUsecaseStyles.Container}>
      <div className={HomeUsecaseStyles.Inner}>
        <p className={HomeUsecaseStyles.SubTitle}>
          <FormattedMessage id="pages.top.usecase.eyebrow" defaultMessage="Usecase" values={{ ...formattedMessageValues }} />
        </p>
        <h2 className={HomeUsecaseStyles.Headline}>
          <FormattedMessage id="pages.top.usecase.title" defaultMessage="活用方法" values={{ ...formattedMessageValues }} />
        </h2>
        <p className={HomeUsecaseStyles.Lead}>
          <FormattedMessage
            id="pages.top.usecase.lead"
            defaultMessage="サイト運営のさまざまなシーンでご活用いただけます。"
            values={{ ...formattedMessageValues }}
          />
        </p>

        <ul className={HomeUsecaseStyles.TopListing}>
          {topUsecases.map((usecase, index) => (
            <li key={`topUsecases_${index}`} className={HomeUsecaseStyles.TopListing_Item}>
              <a href={usecase.link} className={HomeUsecaseStyles.TopCard}>
                <div>
                  <p className={HomeUsecaseStyles.TopCard_Headline}>{usecase.headline}</p>
                  <p className={HomeUsecaseStyles.TopCard_Lead}>{usecase.lead}</p>
                </div>
                <div className={HomeUsecaseStyles.TopCard_Flex}>
                  <div className={HomeUsecaseStyles.TopCard_Image}>
                    <img alt={`${usecase.headline}アイコン`} src={usecase.image} width={110} height={80} />
                  </div>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className={cx(HomeUsecaseStyles.ArrowIcon, SiteWideStyles.DisplayDesktopOnly)}
                    width={14}
                    height={16}
                  />
                </div>
              </a>
            </li>
          ))}
        </ul>

        <h3 className={HomeUsecaseStyles.BottomHeadline}>
          {isLanguageJa() ? (
            'あなたの業界に'
          ) : (
            <FormattedMessage id="pages.top.usecase.bottom.title" defaultMessage="あなたの業界に" values={{ ...formattedMessageValues }} />
          )}
        </h3>

        <div className={HomeUsecaseStyles.BottomContainer}>
          <ul className={HomeUsecaseStyles.BottomListing}>
            {bottomUsecases.map((usecase, index) => (
              <li key={`bottomUsecases_${index}`} className={HomeUsecaseStyles.BottomListing_Item}>
                <a href={usecase.link} className={HomeUsecaseStyles.BottomCard}>
                  <div className={HomeUsecaseStyles.BottomCard_Image}>
                    <img alt={`${usecase.headline}アイコン`} src={usecase.image} width={35} height={32} />
                  </div>
                  <div className={HomeUsecaseStyles.BottomCard_Inner_Grid}>
                    <p className={HomeUsecaseStyles.BottomCard_Headline}>{usecase.headline}</p>
                    <div className={HomeUsecaseStyles.BottomCard_Flex}>
                      <p className={HomeUsecaseStyles.BottomCard_Lead}>{usecase.lead}</p>
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className={cx(HomeUsecaseStyles.ArrowIcon, SiteWideStyles.DisplayDesktopOnly)}
                        width={14}
                        height={16}
                      />
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default HomeUsecase
