import React from 'react'
import * as SiteWideStyles from '../styles/SiteWide.css'
import * as HomeFeatureStyles from '../styles/HomeFeature.css'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import { formattedMessageValues } from '../utils/FormattedMessageValues'

const HomeFeature: React.VFC = () => {
  const blockRef = React.useRef<HTMLDivElement>(null)
  const [scroll, setScroll] = React.useState(false)
  const [feature, setFeature] = React.useState<number>(0)

  const handleTocScroll = React.useCallback(() => {
    blockRef.current &&
      new IntersectionObserver(
        ([entry]) => {
          setScroll(entry.isIntersecting)
        },
        {
          rootMargin: `0px 0px -${window.innerHeight - 100}px 0px`,
        }
      ).observe(blockRef.current)
  }, [blockRef])

  const handleScroll = React.useCallback(() => {
    const sections = blockRef.current?.querySelectorAll('section')

    handleTocScroll()

    window.addEventListener('resize', () => handleTocScroll())

    sections?.forEach((section, index) => {
      new IntersectionObserver(
        ([entry]) => {
          entry.isIntersecting && setFeature(index)
        },
        {
          rootMargin: '-50% 0px',
        }
      ).observe(section)
    })
  }, [blockRef])

  React.useEffect(() => {
    handleScroll()
  }, [handleScroll])

  return (
    <section className={HomeFeatureStyles.Container}>
      <span className={HomeFeatureStyles.SmallHeadline}>
        <FormattedMessage id="pages.top.feature.eyebrow" defaultMessage="Feature" values={{ ...formattedMessageValues }} />
      </span>
      <h2 className={HomeFeatureStyles.Headline}>
        <FormattedMessage id="pages.top.feature.title" defaultMessage="特徴" values={{ ...formattedMessageValues }} />
      </h2>

      <div ref={blockRef} className={HomeFeatureStyles.FeatureBlock}>
        <nav
          className={cx(
            SiteWideStyles.DisplayDesktopOnly,
            HomeFeatureStyles.TableOfContents,
            scroll ? HomeFeatureStyles.TableOfContents_Scroll : ''
          )}>
          <ul className={HomeFeatureStyles.TableOfContents_Listing}>
            <li className={HomeFeatureStyles.TableOfContents_Listing_Item}>
              <a
                href="#feature1"
                className={cx(
                  HomeFeatureStyles.TableOfContents_Listing_Link,
                  feature === 0 && HomeFeatureStyles.TableOfContents_Listing_Current
                )}>
                FEATURE1
                <span className={HomeFeatureStyles.TableOfContents_Listing_Headline}>
                  <FormattedMessage id="pages.top.feature1.title" defaultMessage="直感的な編集" values={{ ...formattedMessageValues }} />
                </span>
              </a>
            </li>
            <li className={HomeFeatureStyles.TableOfContents_Listing_Item}>
              <a
                href="#feature2"
                className={cx(
                  HomeFeatureStyles.TableOfContents_Listing_Link,
                  feature === 1 && HomeFeatureStyles.TableOfContents_Listing_Current
                )}>
                FEATURE2
                <span className={HomeFeatureStyles.TableOfContents_Listing_Headline}>
                  <span className={HomeFeatureStyles.TableOfContents_Listing_Headline}>
                    <FormattedMessage
                      id="pages.top.feature2.title"
                      defaultMessage="思いどおりに配信"
                      values={{ ...formattedMessageValues }}
                    />
                  </span>
                </span>
              </a>
            </li>
            <li className={HomeFeatureStyles.TableOfContents_Listing_Item}>
              <a
                href="#feature3"
                className={cx(
                  HomeFeatureStyles.TableOfContents_Listing_Link,
                  feature === 2 && HomeFeatureStyles.TableOfContents_Listing_Current
                )}>
                FEATURE3
                <span className={HomeFeatureStyles.TableOfContents_Listing_Headline}>
                  <span className={HomeFeatureStyles.TableOfContents_Listing_Headline}>
                    <FormattedMessage
                      id="pages.top.feature3.title"
                      defaultMessage="正しくデータ評価"
                      values={{ ...formattedMessageValues }}
                    />
                  </span>
                </span>
              </a>
            </li>
            <li className={HomeFeatureStyles.TableOfContents_Listing_Item}>
              <a
                href="#feature4"
                className={cx(
                  HomeFeatureStyles.TableOfContents_Listing_Link,
                  feature === 3 && HomeFeatureStyles.TableOfContents_Listing_Current
                )}>
                FEATURE4
                <span className={HomeFeatureStyles.TableOfContents_Listing_Headline}>
                  <span className={HomeFeatureStyles.TableOfContents_Listing_Headline}>
                    <FormattedMessage
                      id="pages.top.feature4.title"
                      defaultMessage="ユーザーを見る"
                      values={{ ...formattedMessageValues }}
                    />
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </nav>

        <section id="feature1" className={HomeFeatureStyles.FeatureBlock_Container}>
          <div className={HomeFeatureStyles.FeatureBlock_Head}>
            <span className={HomeFeatureStyles.FeatureBlock_SmallHeadline}>FEATURE1</span>
            <h3 className={HomeFeatureStyles.FeatureBlock_Headline}>
              <FormattedMessage id="pages.top.feature1.title" defaultMessage="直感的な編集" values={{ ...formattedMessageValues }} />
            </h3>
            <p className={HomeFeatureStyles.FeatureBlock_Lead}>
              <FormattedMessage
                id="pages.top.feature1.lead"
                defaultMessage="あなたのサイトをどこでもブロック化。{brMobile}ノーコードですぐに改修"
                values={{ ...formattedMessageValues }}
              />
            </p>
          </div>
          <dl className={HomeFeatureStyles.Listing}>
            <div className={HomeFeatureStyles.Listing_Item}>
              <dt className={HomeFeatureStyles.Listing_Headline}>
                <FormattedMessage
                  id="pages.top.feature1.item1.title"
                  defaultMessage="見たままをブロック化{br}編集アシストで、変更まで10秒"
                  values={{ ...formattedMessageValues }}
                />
              </dt>
              <dd className={HomeFeatureStyles.Listing_Summary}>
                <ul className={HomeFeatureStyles.Listing_Tags}>
                  <li className={HomeFeatureStyles.Listing_Tag}>
                    <FormattedMessage
                      id="pages.top.feature1.item1.tag"
                      defaultMessage="リッチエディター"
                      values={{ ...formattedMessageValues }}
                    />
                  </li>
                </ul>
                <p className={HomeFeatureStyles.Listing_Description}>
                  <FormattedMessage
                    id="pages.top.feature1.item1.lead"
                    defaultMessage="タグを導入すれば、どんなサイトもブロック化。{br}ブロック単位で置き換え、非表示、新規追加や並び替えまでも直感的に"
                    values={{ ...formattedMessageValues }}
                  />
                </p>
              </dd>
              <dd className={HomeFeatureStyles.Listing_Illustration}>
                <picture>
                  <source srcSet="/assets/images/home/feature_1_1.webp" width="490" height="320" media="(min-width:768px)" />
                  <img
                    src="/assets/images/home/feature_mobile_1_1.webp"
                    width="295"
                    height="200"
                    alt="見たままをブロック化 編集アシストで、変更まで10秒"
                    className={HomeFeatureStyles.Listing_Image}
                    loading="lazy"
                  />
                </picture>
              </dd>
            </div>
            <div className={HomeFeatureStyles.Listing_Item}>
              <dt className={HomeFeatureStyles.Listing_Headline}>
                <span className={HomeFeatureStyles.Listing_CommingSoon}>
                  <FormattedMessage
                    id="pages.top.feature1.item2.title"
                    defaultMessage="プロがデザインしたブロックをワンクリックで設置"
                    values={{ ...formattedMessageValues }}
                  />
                  <span className={HomeFeatureStyles.Listing_CommingSoon_Bubble}>
                    <img
                      src="/assets/images/home/feature_comingsoon.svg"
                      alt="Comming Soon"
                      className={HomeFeatureStyles.Listing_CommingSoon_Image}
                    />
                  </span>
                </span>
              </dt>
              <dd className={HomeFeatureStyles.Listing_Summary}>
                <ul className={HomeFeatureStyles.Listing_Tags}>
                  <li className={HomeFeatureStyles.Listing_Tag}>
                    <FormattedMessage
                      id="pages.top.feature1.item2.tag"
                      defaultMessage="ブロックストア"
                      values={{ ...formattedMessageValues }}
                    />
                  </li>
                </ul>
                <p className={HomeFeatureStyles.Listing_Description}>
                  <FormattedMessage
                    id="pages.top.feature1.item2.lead"
                    defaultMessage="スライドバナーやおすすめコンテンツセクションなど、汎用的なブロックをプロがデザイン。{br}ストアからどれでもインストール、好きなだけカスタマイズ"
                    values={{ ...formattedMessageValues }}
                  />
                </p>
              </dd>
              <dd className={HomeFeatureStyles.Listing_Illustration}>
                <picture>
                  <source srcSet="/assets/images/home/feature_1_2.webp" width="490" height="350" media="(min-width:768px)" />
                  <img
                    src="/assets/images/home/feature_mobile_1_2.webp"
                    width="295"
                    height="182"
                    alt="プロがデザインしたブロックをワンクリックで設置"
                    className={HomeFeatureStyles.Listing_Image}
                    loading="lazy"
                  />
                </picture>
              </dd>
            </div>
          </dl>
          <img
            src="/assets/images/home/feature-background.svg"
            alt=""
            className={cx(HomeFeatureStyles.Listing_Background, SiteWideStyles.DisplayDesktopOnly)}
          />
        </section>

        <section id="feature2" className={HomeFeatureStyles.FeatureBlock_Container}>
          <div className={HomeFeatureStyles.FeatureBlock_Head}>
            <span className={HomeFeatureStyles.FeatureBlock_SmallHeadline}>FEATURE2</span>
            <h3 className={HomeFeatureStyles.FeatureBlock_Headline}>
              <FormattedMessage id="pages.top.feature2.title" defaultMessage="思いどおりに配信" values={{ ...formattedMessageValues }} />
            </h3>
            <p className={HomeFeatureStyles.FeatureBlock_Lead}>
              <FormattedMessage
                id="pages.top.feature2.lead"
                defaultMessage="ABテスト / パーソナライズも自由自在に"
                values={{ ...formattedMessageValues }}
              />
            </p>
          </div>
          <dl className={HomeFeatureStyles.Listing}>
            <div className={HomeFeatureStyles.Listing_Item}>
              <dt className={HomeFeatureStyles.Listing_Headline}>
                <FormattedMessage
                  id="pages.top.feature2.item.title"
                  defaultMessage="手間のかかる実装作業はもう不要"
                  values={{ ...formattedMessageValues }}
                />
              </dt>
              <dd className={HomeFeatureStyles.Listing_Summary}>
                <ul className={HomeFeatureStyles.Listing_Tags}>
                  <li className={HomeFeatureStyles.Listing_Tag}>
                    <FormattedMessage id="pages.top.feature2.item.tag1" defaultMessage="ABテスト" values={{ ...formattedMessageValues }} />
                  </li>
                  <li className={HomeFeatureStyles.Listing_Tag}>
                    <FormattedMessage
                      id="pages.top.feature2.item.tag2"
                      defaultMessage="パーソナライズ配信"
                      values={{ ...formattedMessageValues }}
                    />
                  </li>
                </ul>
                <p className={HomeFeatureStyles.Listing_Description}>
                  <FormattedMessage
                    id="pages.top.feature2.item.lead"
                    defaultMessage="CMSのようにサイトを高速に更新したり、ABテストやパーソナライズでユーザーに合わせて思い通りに届ける。"
                    values={{ ...formattedMessageValues }}
                  />
                </p>
              </dd>
              <dd className={HomeFeatureStyles.Listing_Illustration}>
                <picture>
                  <source srcSet="/assets/images/home/feature_2_1.webp" width="490" height="350" media="(min-width:768px)" />
                  <img
                    src="/assets/images/home/feature_mobile_2_1.webp"
                    width="295"
                    height="230"
                    alt="手間のかかる実装作業はもう不要"
                    className={HomeFeatureStyles.Listing_Image}
                    loading="lazy"
                  />
                </picture>
              </dd>
            </div>
          </dl>
          <img
            src="/assets/images/home/feature-background.svg"
            alt=""
            className={cx(HomeFeatureStyles.Listing_Background, SiteWideStyles.DisplayDesktopOnly)}
          />
        </section>

        <section id="feature3" className={HomeFeatureStyles.FeatureBlock_Container}>
          <div className={HomeFeatureStyles.FeatureBlock_Head}>
            <span className={HomeFeatureStyles.FeatureBlock_SmallHeadline}>FEATURE3</span>
            <h3 className={HomeFeatureStyles.FeatureBlock_Headline}>
              <FormattedMessage id="pages.top.feature3.title" defaultMessage="正しくデータ評価" values={{ ...formattedMessageValues }} />
            </h3>
            <p className={HomeFeatureStyles.FeatureBlock_Lead}>
              <FormattedMessage
                id="pages.top.feature3.lead"
                defaultMessage="効果を自動集計・可視化。{brMobile}データから新たな発見"
                values={{ ...formattedMessageValues }}
              />
            </p>
          </div>
          <dl className={HomeFeatureStyles.Listing}>
            <div className={HomeFeatureStyles.Listing_Item}>
              <dt className={HomeFeatureStyles.Listing_Headline}>
                <FormattedMessage
                  id="pages.top.feature3.item1.title"
                  defaultMessage="サイトのKPIごとに成果を可視化"
                  values={{ ...formattedMessageValues }}
                />
              </dt>
              <dd className={HomeFeatureStyles.Listing_Summary}>
                <ul className={HomeFeatureStyles.Listing_Tags}>
                  <li className={HomeFeatureStyles.Listing_Tag}>
                    <FormattedMessage
                      id="pages.top.feature3.item1.tag"
                      defaultMessage="計測・統計判定"
                      values={{ ...formattedMessageValues }}
                    />
                  </li>
                </ul>
                <p className={HomeFeatureStyles.Listing_Description}>
                  <FormattedMessage
                    id="pages.top.feature3.item1.lead"
                    defaultMessage="最終CVだけで効果を追っていませんか?{brDesktop}サイトや施策目的にあったKPIを設定。横断的な効果分析も、SQL不要。"
                    values={{ ...formattedMessageValues }}
                  />
                </p>
              </dd>
              <dd className={HomeFeatureStyles.Listing_Illustration}>
                <picture>
                  <source srcSet="/assets/images/home/feature_3_1.webp" width="490" height="330" media="(min-width:768px)" />
                  <img
                    src="/assets/images/home/feature_mobile_3_1.webp"
                    width="295"
                    height="212"
                    alt="サイトのKPIごとに成果を可視化"
                    className={HomeFeatureStyles.Listing_Image}
                    loading="lazy"
                  />
                </picture>
              </dd>
            </div>
            <div className={HomeFeatureStyles.Listing_Item}>
              <dt className={HomeFeatureStyles.Listing_Headline}>
                <FormattedMessage
                  id="pages.top.feature3.item2.title"
                  defaultMessage="ブロックのパフォーマンスをチェック"
                  values={{ ...formattedMessageValues }}
                />
              </dt>
              <dd className={HomeFeatureStyles.Listing_Summary}>
                <ul className={HomeFeatureStyles.Listing_Tags}>
                  <li className={HomeFeatureStyles.Listing_Tag}>
                    <FormattedMessage
                      id="pages.top.feature3.item2.tag1"
                      defaultMessage="カスタムイベント計測"
                      values={{ ...formattedMessageValues }}
                    />
                  </li>
                  <li className={HomeFeatureStyles.Listing_Tag}>
                    <FormattedMessage
                      id="pages.top.feature3.item2.tag2"
                      defaultMessage="ブロック別効果"
                      values={{ ...formattedMessageValues }}
                    />
                  </li>
                </ul>
                <p className={HomeFeatureStyles.Listing_Description}>
                  <FormattedMessage
                    id="pages.top.feature3.item2.lead"
                    defaultMessage="ヒートマップのように、ブロックの表示数、クリック率、経由CV率などを自動で可視化。ツールをまたがず、そのままワンストップで改善に繋げられる。"
                    values={{ ...formattedMessageValues }}
                  />
                </p>
              </dd>
              <dd className={HomeFeatureStyles.Listing_Illustration}>
                <picture>
                  <source srcSet="/assets/images/home/feature_3_2.webp" width="490" height="308" media="(min-width:768px)" />
                  <img
                    src="/assets/images/home/feature_mobile_3_2.webp"
                    width="295"
                    height="198"
                    alt="ブロックのパフォーマンスをチェック"
                    className={HomeFeatureStyles.Listing_Image}
                    loading="lazy"
                  />
                </picture>
              </dd>
            </div>
          </dl>
          <img
            src="/assets/images/home/feature-background.svg"
            alt=""
            className={cx(HomeFeatureStyles.Listing_Background, SiteWideStyles.DisplayDesktopOnly)}
          />
        </section>

        <section id="feature4" className={HomeFeatureStyles.FeatureBlock_Container}>
          <div className={HomeFeatureStyles.FeatureBlock_Head}>
            <span className={HomeFeatureStyles.FeatureBlock_SmallHeadline}>FEATURE4</span>
            <h3 className={HomeFeatureStyles.FeatureBlock_Headline}>
              <FormattedMessage id="pages.top.feature4.title" defaultMessage="ユーザーを見る" values={{ ...formattedMessageValues }} />
            </h3>
            <p className={HomeFeatureStyles.FeatureBlock_Lead}>
              <FormattedMessage
                id="pages.top.feature4.lead"
                defaultMessage="ユーザーの反応を深掘り。{brMobile}次につながる発見と仮説を生み出す"
                values={{ ...formattedMessageValues }}
              />
            </p>
          </div>
          <dl className={HomeFeatureStyles.Listing}>
            <div className={HomeFeatureStyles.Listing_Item}>
              <dt className={HomeFeatureStyles.Listing_Headline}>
                <span className={HomeFeatureStyles.Listing_CommingSoon}>
                  <FormattedMessage
                    id="pages.top.feature4.item1.title"
                    defaultMessage="セグメント別の反応をワンクリックで可視化"
                    values={{ ...formattedMessageValues }}
                  />
                </span>
              </dt>
              <dd className={HomeFeatureStyles.Listing_Summary}>
                <ul className={HomeFeatureStyles.Listing_Tags}>
                  <li className={HomeFeatureStyles.Listing_Tag}>
                    <FormattedMessage
                      id="pages.top.feature4.item1.tag"
                      defaultMessage="セグメント別効果解析"
                      values={{ ...formattedMessageValues }}
                    />
                  </li>
                </ul>
                <p className={HomeFeatureStyles.Listing_Description}>
                  <FormattedMessage
                    id="pages.top.feature4.item1.lead"
                    defaultMessage="全体数値の中で特定の顧客群が見落とされてしまうケースも。{brDesktop}セグメント別の分析を手間なく実施、具体的な課題と成果が見つかる。"
                    values={{ ...formattedMessageValues }}
                  />
                </p>
              </dd>
              <dd className={HomeFeatureStyles.Listing_Illustration}>
                <picture>
                  <source srcSet="/assets/images/home/feature_4_1.webp" width="490" height="300" media="(min-width:768px)" />
                  <img
                    src="/assets/images/home/feature_mobile_4_1.webp"
                    width="295"
                    height="194"
                    alt="セグメント別の反応をワンクリックで可視化"
                    className={HomeFeatureStyles.Listing_Image}
                    loading="lazy"
                  />
                </picture>
              </dd>
            </div>
            <div className={HomeFeatureStyles.Listing_Item}>
              <dt className={HomeFeatureStyles.Listing_Headline}>
                <span className={HomeFeatureStyles.Listing_CommingSoon}>
                  <FormattedMessage
                    id="pages.top.feature4.item2.title"
                    defaultMessage="生の顧客体験をリプレイ"
                    values={{ ...formattedMessageValues }}
                  />
                </span>
              </dt>
              <dd className={HomeFeatureStyles.Listing_Summary}>
                <ul className={HomeFeatureStyles.Listing_Tags}>
                  <li className={HomeFeatureStyles.Listing_Tag}>
                    <FormattedMessage
                      id="pages.top.feature4.item2.tag"
                      defaultMessage="ライブリプレイ"
                      values={{ ...formattedMessageValues }}
                    />
                  </li>
                </ul>
                <p className={HomeFeatureStyles.Listing_Description}>
                  <FormattedMessage
                    id="pages.top.feature4.item2.lead"
                    defaultMessage="セッション時の閲覧状況を動画で確認。{brDesktop}改修による体験変化を捉え、結果につながる原因（WHY）を見つける。"
                    values={{ ...formattedMessageValues }}
                  />
                </p>
              </dd>
              <dd className={HomeFeatureStyles.Listing_Illustration}>
                <picture>
                  <source srcSet="/assets/images/home/feature_4_2.webp" width="490" height="350" media="(min-width:768px)" />
                  <img
                    src="/assets/images/home/feature_mobile_4_2.webp"
                    width="295"
                    height="215"
                    alt="セグメント別の反応をワンクリックで可視化"
                    className={HomeFeatureStyles.Listing_Image}
                    loading="lazy"
                  />
                </picture>
              </dd>
            </div>
          </dl>
          <img
            src="/assets/images/home/feature-background.svg"
            alt=""
            className={cx(HomeFeatureStyles.Listing_Background, SiteWideStyles.DisplayDesktopOnly)}
          />
        </section>
      </div>
    </section>
  )
}

export default HomeFeature
