import React from 'react'
import * as HomeNoticeStyles from '../styles/HomeNotice.css'
import { FormattedMessage } from 'react-intl'
import { isLanguageJa } from '../utils/is-language-ja'
import { formattedMessageValues } from '../utils/FormattedMessageValues'

const HomeNotice: React.VFC = () => {
  return (
    <div className={HomeNoticeStyles.Content}>
      <div className={HomeNoticeStyles.Inner}>
        <span className={HomeNoticeStyles.Tag}>NEW</span>
        <a href="https://plaid.co.jp/news/20230124-2/" target="_blank" rel="noopener" className={HomeNoticeStyles.Text}>
          {isLanguageJa() ? (
            '2期連続受賞!「ITreview Grid Award 2023 Winter」の「ABテストツール」「LPOツール」部門で「High Performer」'
          ) : (
            <FormattedMessage
              id="pages.top.notice"
              defaultMessage="2期連続受賞!「ITreview Grid Award 2023 Winter」の「ABテストツール」「LPOツール」部門で「High Performer」"
              values={{ ...formattedMessageValues }}
            />
          )}
        </a>
      </div>
    </div>
  )
}

export default HomeNotice
